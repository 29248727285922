import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { sizes } from "../device"

const useImages = () => {
  const data = useStaticQuery(graphql`
    query {
      upperBg: file(relativePath: { eq: "reasons/reasons-bg-upper.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      upperBgMobile: file(
        relativePath: { eq: "reasons/reasons-bg-upper.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      lowerBg: file(relativePath: { eq: "reasons/reasons-bg-lower.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lowerBgMobile: file(
        relativePath: { eq: "reasons/reasons-bg-lower.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return data
}

const UpperBg = () => {
  const data = useImages()
  const sources = [
    data.upperBgMobile.childImageSharp.fluid,
    {
      ...data.upperBg.childImageSharp.fluid,
      media: `(min-width: ${sizes.small}px)`,
    },
  ]
  return <Img fluid={sources} />
}

const LowerBg = () => {
  const data = useImages()
  const sources = [
    data.lowerBgMobile.childImageSharp.fluid,
    {
      ...data.lowerBg.childImageSharp.fluid,
      media: `(min-width: ${sizes.small}px)`,
    },
  ]
  return <Img fluid={sources} objectFit="contain" objectPosition="left" />
}

export { UpperBg, LowerBg }
