import React from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import Slider from "react-slick"
import { PlaceholderImage } from "../noResults"

const Wrapper = styled.div`
  margin: 0rem 0 2rem 7.5%;
  .slick-slide {
    padding: 0.5rem;
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    width: var(--spread);
    .slick-active {
      div {
        background: var(--white) !important;
      }
    }
  }
`

const Slide = styled.div`
  margin: 2rem 0 3rem 0;
  .content {
    border-radius: 25px;
    background: var(--white);
    text-align: center;
    padding: 2rem;
    .image {
      filter: var(--green-filter);
      margin: 0 auto;
      display: block;
      width: 50px;
    }
    h4 {
      font-weight: 700;
      margin: 1rem 0;
    }
    p {
      font-size: 0.9rem;
    }
  }
`

const ReasonsSlider = ({ posts }) => {
  const settings = {
    infinite: false,
    slidesToShow: 1.25,
    arrows: false,
    dots: true,
    customPaging: i => (
      <div
        style={{
          width: "15px",
          height: "15px",
          background: "var(--grey)",
          borderRadius: "50%",
          margin: "0 0.2rem",
        }}
      ></div>
    ),
  }
  return (
    <Wrapper>
      <Slider {...settings}>
        {posts.map((post, i) => (
          <div className="swiper-slide" key={i}>
            <Slide className="slide-slide">
              <div className="content">
                {post.featured_media && post.featured_media.localFile ? (
                  <Img
                    className="image"
                    fluid={post.featured_media.localFile.childImageSharp.fluid}
                    alt={post.featured_media.alt_text}
                  />
                ) : (
                  <PlaceholderImage className="image" />
                )}
                <h4>{post.title.replace(/&#038;/g, "&")}</h4>
                <p>{post.acf.text}</p>
              </div>
            </Slide>
          </div>
        ))}
      </Slider>
    </Wrapper>
  )
}

export default ReasonsSlider
