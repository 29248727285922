import React from "react"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { PlaceholderImage } from "../noResults"
import plus from "../../images/reasons/add.svg"
import cross from "../../images/reasons/delete.svg"
import Slider from "./reasons-slider"

const List = styled.div`
  margin: 2rem 0;
  .list-item {
    margin: 2rem auto 0 auto;
    width: var(--spread);
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--grey);
    padding: 0.5rem 0;
    position: relative;
    .cat-img {
      width: 30px;
      filter: var(--green-filter);
    }
    span {
      margin-left: 0.5rem;
      color: var(--white);
      font-family: "Theano Didot";
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 0.8rem;
    }
    .expander {
      display: block;
      padding: 0 1rem;
      position: absolute;
      right: 0;
      top: 50%;
      img {
        width: 8px;
      }
    }
  }
`

const ReasonsMobile = ({ reasons, handleClick, selected }) => {
  return (
    <List>
      {reasons.map((item, index) => (
        <div key={index}>
          <div className="list-item" onClick={handleClick} data-index={index}>
            {item.image ? (
              <Img
                className="cat-img"
                fluid={item.image.localFile.childImageSharp.fluid}
                alt={item.image.alt_text}
              />
            ) : (
              <PlaceholderImage className="cat-img" />
            )}
            <span>{item.category}</span>
            <span className="expander">
              <img
                src={index === parseInt(selected) ? cross : plus}
                alt="Expander"
              />
            </span>
          </div>
          {index === parseInt(selected) ? (
            <Slider posts={reasons[selected].posts} />
          ) : null}
        </div>
      ))}
    </List>
  )
}

export default ReasonsMobile
